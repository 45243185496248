import { APP_INITIALIZER, ErrorHandler, Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, Router, RouterModule } from '@angular/router';
import { AuthService } from '@bp-core/src/lib/services/portal/auth.service';

import { environment } from '@environment/environment';
// import { FuseMockApiModule } from '@fuse/lib/mock-api';
// import { FuseConfigModule } from '@fuse/services/config';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localeESCL from '@angular/common/locales/es-CL';
import localeESCLExtra from '@angular/common/locales/extra/es-CL';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { GrpcLoggerModule } from '@bp-core/src/lib/modules/grpc-logger/grpc-logger.module';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { GrpcMessage } from '@ngx-grpc/common';
import { GRPC_INTERCEPTORS, GrpcCoreModule } from '@ngx-grpc/core';
import { GrpcWebClientModule } from '@ngx-grpc/grpc-web-client';
import * as Sentry from '@sentry/angular-ivy';

import { CustomRouter } from '@bp-core/src/lib/services/custom-router';
import { provideFuse } from '@fuse';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { CustomPsmServiceService } from './customPsmService.service';

import { provideLumberjack } from '@ngworker/lumberjack';
import { provideLumberjackConsoleDriver } from '@ngworker/lumberjack/console-driver';
import { IPasswordStrengthMeterService } from 'angular-password-strength-meter';
import { Angulartics2Module } from 'angulartics2';
import { AppComponent } from 'app/app.component';
import { provideAuth } from 'app/core/auth/auth.provider';
import { provideIcons } from 'app/core/icons/icons.provider';
import { provideTransloco } from 'app/core/transloco/transloco.provider';
import { LayoutComponent } from 'app/layout/layout.component';
import { bpAppRoutes, notMatchRedirectRoutes } from 'bp/app.routing';
import { BpConfirmationService } from 'bp/shared/services/bp-confirmation.service';
import { BpPendingNotificationsService } from 'bp/shared/services/bp-pending-notifications.service';
import { ComponentFactoryService } from 'bp/shared/services/component-factory.service';
import { UserStateService } from 'bp/shared/services/user-state.service';
import { provideClarity } from 'ngx-clarity';
import { MarkdownModule } from 'ngx-markdown';
import { GrpcLoadingInterceptor } from 'projects/bp-advisor/src/bp/shared/interceptors/grpc-loading.interceptor';
import { BpLoadingInterceptor } from 'projects/bp-advisor/src/bp/shared/interceptors/loading.interceptor';
import { OAuthModule } from 'projects/bp-core/src/lib/external/angular-oauth2-oidc-jwks/public_api';
import { AuthGrpcInterceptor } from 'projects/bp-core/src/lib/services/portal/auth-grpc.interceptor';
import { BpHeaderService } from '../bp/shared/services/bp-header.service';
import { mockApiServices } from './mock-api';
const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled',
};
let portalRoutes = [...bpAppRoutes, ...notMatchRedirectRoutes];

if (environment.production) {
  portalRoutes = [...bpAppRoutes, ...notMatchRedirectRoutes];
}

const providers: any[] = [
  provideClarity({
    enabled: environment.name == 'Production',
    projectId: 'hrh0x7c9ow',
  }),
  { provide: GRPC_INTERCEPTORS, useClass: GrpcLoadingInterceptor, multi: true },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: BpLoadingInterceptor,
    multi: true,
  },
  {
    provide: IPasswordStrengthMeterService,
    useClass: CustomPsmServiceService,
  },
  CustomRouter,
  {
    provide: Router,
    useClass: CustomRouter,
  },
  /*{
    provide: FUSE_CONFIG,
    useValue: appConfig,
  },*/
  {
    provide: LOCALE_ID,
    useValue: 'es-CL',
  },
  {
    provide: GRPC_INTERCEPTORS,
    useClass: AuthGrpcInterceptor,
    multi: true,
  },
  {
    provide: DateAdapter,
    useClass: LuxonDateAdapter,
  },
  {
    provide: MAT_DATE_FORMATS,
    useValue: {
      parse: {
        dateInput: 'D',
      },
      display: {
        dateInput: 'DDD',
        monthYearLabel: 'LLL yyyy',
        dateA11yLabel: 'DD',
        monthYearA11yLabel: 'LLLL yyyy',
      },
    },
  },

  // Transloco Config
  provideTransloco(),

  // Fuse
  provideAuth(),
  provideIcons(),
  provideFuse({
    mockApi: {
      delay: 0,
      services: mockApiServices,
    },
    fuse: {
      layout: 'classy',
      scheme: 'light',
      screens: {
        sm: '600px',
        md: '960px',
        lg: '1280px',
        xl: '1440px',
      },
      theme: 'theme-bp',
      themes: [
        {
          id: 'theme-bp',
          name: 'Betterplan',
        },
        {
          id: 'theme-default',
          name: 'Default',
        },
        {
          id: 'theme-brand',
          name: 'Brand',
        },
        {
          id: 'theme-teal',
          name: 'Teal',
        },
        {
          id: 'theme-rose',
          name: 'Rose',
        },
        {
          id: 'theme-purple',
          name: 'Purple',
        },
        {
          id: 'theme-amber',
          name: 'Amber',
        },
      ],
    },
  }),
  BpPendingNotificationsService,
  UserStateService,
  ComponentFactoryService,
  BpHeaderService,
  provideLumberjack(),
  provideLumberjackConsoleDriver(),
];

@Injectable()
export class DevelopErrorHandler implements ErrorHandler {
  constructor(private bpConfirmationService: BpConfirmationService) {}
  handleError(error: any) {
    const whiteList = ['Argumento de constructor de matriz con tipo no válido', '_avast_submit'];

    whiteList.forEach(element => {
      if (error.message?.includes(element)) {
        console.error('white-list error:', [error]);
        return;
      }
    });

    console.log(error);

    console.error('dev error handler:', error);
    this.bpConfirmationService.open(
      {
        title: 'Error no controlado: ' + error.name,
        message: error.message,
      },
      'error',
    );
    /*Swal.fire({
      iconHtml: '<i class="icon icon-swal fas fa-exclamation-triangle"></i>',
      customClass: {
        container: 'swal2-fixe',
        confirmButton: 'btn',
        cancelButton: 'btn',
      },
      buttonsStyling: false,
      showClass: {
        icon: 'swal2-noanimation',
      },
      hideClass: {
        icon: 'swal2-noanimation',
      },
      footer:
        '<div class="border"><div class="border-section"></div><div class="border-section"></div><div class="border-section"></div><div class="border-section"></div><div class="border-section"></div><div class="border-section"></div></div>',
      icon: 'error',
      title: 'Error no controlado:' + error.name,
      text: error.message,
    });*/
    // LogRocket.captureException(error);
  }
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor(private authService: AuthService) {}
  handleError(error: any) {
    const whiteList = ['Argumento de constructor de matriz con tipo no válido', '_avast_submit'];

    whiteList.forEach(element => {
      if (error.message?.includes(element)) {
        console.error('white-list error:', [error]);
        return;
      }
    });

    console.log(error);
    const eventId = Sentry.captureException(error.originalError || error);
    const user = this.authService.userSubject$.value;
    const name = this.authService.userSubject$.value
      ? this.authService.userSubject$.value.firstname +
        ' ' +
        this.authService.userSubject$.value.surname
      : '';
    const username = this.authService.userSubject$.value
      ? this.authService.userSubject$.value.email
      : '';
    // Sentry.configureScope(scope => {
    //   scope.setExtra('sessionURL', LogRocket.sessionURL);
    // });
    let userRaw: any = {};
    if (user) {
      userRaw = user?.toProtobufJSON();
    }
    Sentry.showReportDialog({
      eventId,
      lang: 'es',
      user: {
        ...userRaw,
        name,
        email: username,
      },
      title: 'Parece que estamos teniendo problemas.',
    });
    //LogRocket.captureException(error);
  }
}
if (environment.name == 'Production') {
  datadogRum.init({
    applicationId: '0d630ad3-8644-481a-b9f2-980aebfeacdc',
    clientToken: 'pubc88440ec02b738176a38b7dfea76a33f',
    site: 'us3.datadoghq.com',
    service: 'bp-client-advisor',
    env: environment.name,
    version: environment.version,
    sessionSampleRate: 20,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    allowedTracingUrls: [
      window.location.origin,
      /^https:\/\/[^/]+\.betterplan\.cl\/.*/,
      /^https:\/\/[^/]+\.fintoc\.com\/.*/,
    ],
  });
  datadogLogs.init({
    clientToken: 'pubc88440ec02b738176a38b7dfea76a33f',
    site: 'us3.datadoghq.com',
    forwardErrorsToLogs: true,
    sessionSampleRate: 20,
    env: environment.name,
    version: environment.version,
    service: 'bp-client-advisor',
    forwardConsoleLogs: 'all',
  });
  Sentry.init({
    dsn: 'https://de4a8d7dd83346cb8cf9c1c15a6cce21@sentry.io/1785226',
    debug: !environment.production,
    release: environment.version,
    environment: environment.name,
    replaysSessionSampleRate: 0.0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    tracePropagationTargets: [/^https:\/\/[^/]+\.betterplan\.cl\/.*/],

    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      new Sentry.Replay({
        blockAllMedia: false,
        maskAllText: false,
        maskAllInputs: false,
        networkDetailAllowUrls: [/^https:\/\/[^/]+\.betterplan\.cl\/.*/],
        networkRequestHeaders: ['X-Custom-Header'],
        networkResponseHeaders: ['X-Custom-Header'],
      }),
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
  });
  //LogRocket.init('xc7ami/betterplan-portal-vnext', {
  //release: `${environment.version}-${environment.name}`,
  //});

  providers.push(
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  );
} else {
  providers.push({ provide: ErrorHandler, useClass: DevelopErrorHandler });
}

// @Injectable()
// export class CustomPsmServiceService extends IPasswordStrengthMeterService {
//   score(password: string): number {
//     const passWithTrim = password.trim();

//     if (passWithTrim.length >= 10 && /[A-Z]/.test(passWithTrim) && /\d/.test(passWithTrim)) {
//       return 4;
//     } else if (passWithTrim.length >= 8 && /[A-Z]/.test(passWithTrim) && /\d/.test(passWithTrim)) {
//       return 3;
//     } else if (passWithTrim.length >= 8 && /[A-Z]/.test(passWithTrim)) {
//       return 2;
//     } else if (passWithTrim.length >= 8) {
//       return 1;
//     } else {
//       return 0;
//     }
//   }

//   // example code for avoid the no-implement error
//   scoreWithFeedback(
//     password: string,
//   ): {
//     score: number;
//     feedback: { warning: string; suggestions: string[] };
//   } {
//     return { score: 1, feedback: { warning: '', suggestions: [] } };
//   }
// }

registerLocaleData(localeESCL, localeESCLExtra);

export const customCurrencyMaskConfig = {
  align: 'left',
  allowNegative: false,
  allowZero: false,
  decimal: ',',
  precision: 0,
  prefix: '$',
  suffix: '',
  thousands: '.',
  nullable: true,
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(portalRoutes, routerConfig),

    // Fuse, FuseConfig & FuseMockAPI
    // FuseConfigModule.forRoot(appConfig),
    // FuseMockApiModule.forRoot(mockApiServices),

    // Core module of your application

    // Layout module of your application
    LayoutComponent,

    // 3rd party modules that require global configuration via forRoot
    Angulartics2Module.forRoot(),
    OAuthModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    GoogleTagManagerModule.forRoot({
      id: 'GTM-N3S8WSB',
    }),
    MarkdownModule.forRoot({}),
    GrpcCoreModule.forRoot(),
    GrpcWebClientModule.forRoot({
      settings: {
        host: environment.urlGrpc,
        format: 'binary',
        withCredentials: false,
        suppressCorsPreflight: false,
      },
    }),
    GrpcLoggerModule.forRoot({
      settings: {
        // enables logger in dev mode and still lets you see them in production when running `localStorage.setItem('logger', 'true') in the console`
        // enabled:
        //   localStorage.getItem('logger') === 'true' || !environment.production,
        enabled: true,
        // protobuf json is more human-readable than the default toObject() mapping
        // please beware: if you use google.protobuf.Any you must pass the proper `messagePool` argument
        requestMapper: (msg: GrpcMessage) => msg.toProtobufJSON(),
        responseMapper: (msg: GrpcMessage) => msg.toProtobufJSON(),
      },
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
  ],

  providers,
  bootstrap: [AppComponent],
})
export class AppModule {}
